import Header from "../../Components/Header";
import "./style.css";
import back from "./back.png";
import Flip from "react-reveal/Flip";
import Bounce from "react-reveal/Bounce";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
export default function Form() {
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const Navigate = useNavigate();
  function sendForm() {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("phone", tel);
    fetch("https://brandside.pro/amocrm/webhook.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.status == 200) {
        Navigate(routes.thanks);
      }
    });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Form">
      <div className="formHeader">
        <Header />
        <Bounce>
          <h3>Введите актуальные данные для связи</h3>
        </Bounce>
        <Bounce delay={200}>
          <h4>свяжемся с вами в течение 1 минуты</h4>
        </Bounce>
      </div>
      <Flip top delay={400}>
        <div className="form">
          <div className="formBody">
            <div className="inputsForm">
              <p>Ваше имя</p>
              <input
                type="text"
                placeholder="Имя"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="inputsForm">
              <p>
                Ваш номер телефона<span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="tel"
                placeholder="8-999-999-99-99"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
              />
            </div>
            <div className="vibor">
              <input type="radio" name="types" checked id="zvon" />
              <label htmlFor="zvon">Позвонить</label>
              <input type="radio" name="types" id="text" />
              <label htmlFor="text">Написать</label>
            </div>
            <div
              className="sendForm"
              style={
                tel.length < 7 ? { opacity: "0.6", pointerEvents: "none" } : {}
              }
              onClick={() => {
                sendForm();
              }}
            >
              Отправить
            </div>
          </div>
        </div>
      </Flip>
    </div>
  );
}
