import icon1 from "./1.svg";
import icon2 from "./2.svg";
import icon3 from "./3.svg";
import icon4 from "./4.svg";
import woman from "./g.png";
import message from "./message.svg";
import logo from "./logo.svg";
import back from "./back.png";
import HeadShake from "react-reveal/HeadShake";
import "./style.css";
import Typewriter from "typewriter-effect";
import { Flip } from "react-reveal";
import Zoom from "react-reveal/Zoom";
import Rotate from "react-reveal/Rotate";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

export default function Main() {
  const Navigate = useNavigate();
  return (
    <div className="Main">
      <div className="oval" style={{ backgroundImage: `url(${back})` }}>
        <header>
          <img src={logo} alt="" className="logo" />
          <Zoom left delay={1200}>
            <a href="https://t.me/oporadigital_bot" className="send">
              <img src={message} alt="" />
              <p>Написать</p>
            </a>
          </Zoom>
          <img src={woman} className="manager" alt="" />
        </header>
        <h1>
          <b>SMART</b>
          <span>Регистрация</span>
        </h1>
        <div className="znaks">товарных знаков</div>
      </div>
      <div className="main_content">
        <div className="blocks">
          <Rotate top left>
            <div
              className="block"
              onClick={() => {
                Navigate(routes.services);
              }}
            >
              <img src={icon1} alt="" />

              <div>
                <p>Регистрация товарного знака</p>
              </div>
            </div>
          </Rotate>
          <Rotate top right>
            <div
              className="block"
              onClick={() => {
                Navigate(routes.objects);
              }}
            >
              <img src={icon2} alt="" />

              <div>
                <p>Специальные предложения</p>
              </div>
            </div>
          </Rotate>
        </div>

        <div className="blocks">
          <Rotate top left>
            <div
              className="block bl3"
              onClick={() => {
                Navigate(routes.team);
              }}
            >
              <img src={icon3} alt="" />

              <div>
                <p>Эксперсс-проверка товарного знака</p>
              </div>
            </div>
          </Rotate>
          <Rotate top right>
            <div
              className="block bl4"
              onClick={() => {
                Navigate(routes.form);
              }}
            >
              <img src={icon4} alt="" />

              <div>
                <p>
                  Записаться <br /> на консультацию
                </p>
              </div>
            </div>
          </Rotate>
        </div>
      </div>
    </div>
  );
}
