import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import img1 from "./1.svg";
import img2 from "./2.svg";
import img3 from "./3.svg";

import "./style.css";
import Zoom from "react-reveal/Zoom";
import { routes } from "../../routes";
export default function Team() {
  const Navigate = useNavigate();
  return (
    <div className="Team">
      <Header />{" "}
      <Zoom top>
        <h3 className="zag">Экспресс-проверка</h3>
      </Zoom>
      <Zoom bottom>
        <p className="pred">товарного знака</p>{" "}
      </Zoom>
      <div className="znaks">Этапы: все просто, как 1 - 2 - 3</div>
      <Zoom left>
        {" "}
        <div className="tovarT">
          <img src={img1} alt="" />
          <p>Получаем информацию о товарном знаке и продуктах/услугах</p>
          <div>1</div>
        </div>
      </Zoom>
      <Zoom right>
        {" "}
        <div className="tovarT">
          <img src={img2} alt="" />
          <p>Проверяем на тождество по зарегистрированным товарными знаками</p>
          <div>2</div>
        </div>
      </Zoom>
      <Zoom left>
        {" "}
        <div className="tovarT">
          <img src={img3} alt="" />
          <p>Консультируем по регистрации товарного знака</p>
          <div>3</div>
        </div>
      </Zoom>
      <div
        className="sendForm"
        onClick={() => {
          Navigate(routes.form);
        }}
      >
        Заказать <br /> Экспресс-проверку
      </div>
      <p className="besp">Это бесплатно</p>
    </div>
  );
}
