import { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { Url } from "../../const";
import { routes } from "../../routes";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Roll from "react-reveal/Roll";
import icon1 from "./1.svg";
import icon2 from "./2.svg";
import icon3 from "./3.svg";
import { Zoom } from "react-reveal";
export default function Objects({ objects }) {
  const Navigate = useNavigate();

  return (
    <div className="objects">
      <Header />
      <h3 className="zag">СПЕЦИАЛЬНЫЕ</h3>
      <p className="pred">ПРЕДЛОЖЕНИЯ</p>
      <Zoom top>
        <div className="special">
          <div className="topSpecial">
            <img src={icon1} alt="" />
            <p>Скидка 50%</p>
          </div>
          <div className="bottomSpecial">
            <p>при регистрации двух и более товарных знаков</p>
          </div>
        </div>
      </Zoom>
      <div className="twoSpecial">
        <Zoom left>
          <div className="special">
            <div className="topSpecial">
              <img src={icon1} alt="" />
              <p>Рассрочка 0%</p>
            </div>
            <div className="bottomSpecial">
              <p>от 2х месяцев</p>
            </div>
          </div>
        </Zoom>
        <Zoom right>
          <div className="special">
            <div className="topSpecial">
              <img src={icon1} alt="" />
              <p>Скидка 30%</p>
            </div>
            <div className="bottomSpecial">
              <p>на оплату госпошлин</p>
            </div>
          </div>
        </Zoom>
      </div>
      <p className="pis">
        Интересно? <span>Пишите</span>
      </p>
      <div
        className="sendForm"
        onClick={() => {
          Navigate(routes.form);
        }}
      >
        Обсудить условия
      </div>
    </div>
  );
}
